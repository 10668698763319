import { useEffect, useRef } from "react";

export function useSafeCallback(): (callback: () => void) => void {
  const mountedRef = useRef(false);

  // effect just for tracking mounted state
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (callback: () => void) => {
    // only execute callback when mounted
    if (mountedRef.current) callback();
  };
}
