import React from "react";
import cx from "classnames";
import { Trans, useTranslation } from "react-i18next";
import eyeIcon from "~/images/icons/eye.svg";

interface Props {
  className?: string;
  logo: string;
  alt: string;
  count: number;
  href: string;
}

const AuditCard: React.FC<Props> = ({ className, logo, alt, count, href }) => {
  const { t } = useTranslation();

  return (
    <div
      className={cx(
        "rounded-lg bg-dark-500 border-gray-100 border px-0",
        className
      )}
    >
      <div className="h-44 flex justify-center items-center px-3 lg:pt-7 lg:h-36">
        <img src={logo} alt={alt} className="lg:w-2/3" />
      </div>
      <div className="flex justify-between border-t border-gray-100 py-2 px-3">
        <div className="w-1/2 flex items-center text-xs font-thin leading-3 gap-x-1">
          <Trans count={count} i18nKey="auditCompleted">
            <strong className="text-2xl font-semibold lg:mr-1">
              {{ count }}
            </strong>
            <div>
              Audits
              <br />
              Completed
            </div>
          </Trans>
        </div>
        <a
          href={href}
          target="_blank"
          rel="nofollow noopener noreferrer"
          className="w-1/2 flex justify-between items-center"
        >
          <img src={eyeIcon} alt="Eye" className="ml-auto" />
          <span className="hidden lg:block lg:ml-2 text-xs font-medium">
            {t("View reports")}
          </span>
        </a>
      </div>
    </div>
  );
};
export default AuditCard;
