import React from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import texture from "~/images/graphics/texture.png";

interface Props {
  className?: string;
  top: React.ReactNode;
  title?: string;
  href: string;
}

const SecurityHighlightCard: React.FC<Props> = ({
  className,
  top,
  title,
  href,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={cx(
        "border border-primary rounded-xl p-5 text-center lg:border-0 lg:relative lg:overflow-hidden",
        className
      )}
    >
      <div className="relative">
        <div className="h-48 flex justify-center items-center lg:h-56">
          {top}
        </div>
        <div className="h-14 text-xl font-bold lg:mt-8 lg:h-7">{title}</div>
        <p className="h-16 text-l lg:h-14 lg:mt-8">{children}</p>
        <a
          href={href}
          target="_blank"
          rel="nofollow noopener noreferrer"
          className="block mt-4 text-xs font-bold lg:mt-6"
        >
          {t("Learn More")}
        </a>
      </div>
    </div>
  );
};

export default SecurityHighlightCard;
