import axios from "axios";
import { useEffect, useState } from "react";
import {
  IAutomatedVaultV3Summary,
  IMoneyMarketSummary,
  ISummary,
} from "~/entity";
import { useRefreshOneMinute } from "../utility/useRefreshData";
import { useSafeCallback } from "../utility/useSafeCallback";

type IUsePrepareData = {
  summary: ISummary;
  moneyMarketSummary: IMoneyMarketSummary;
  automatedVaultV3Summary: IAutomatedVaultV3Summary;
  loading: boolean;
  ready: boolean;
};

const getData = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`response ${url} data source not ok`);
  }
  return response.json();
};

export function usePrepareData(): IUsePrepareData {
  const [loading, setLoading] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>(false);
  const [summary, setSummary] = useState<ISummary | null>(null);
  const [moneyMarketSummary, setMoneyMarketSummary] =
    useState<IMoneyMarketSummary | null>(null);
  const [automatedVaultV3Summary, setAutomatedVaultV3Summary] =
    useState<IAutomatedVaultV3Summary | null>(null);

  const safeCallback = useSafeCallback();
  const { refreshTimeRef, refreshing, setRefreshing } = useRefreshOneMinute();
  const urlBSC =
    "https://alpaca-static-api.alpacafinance.org/bsc/v1/landing/summary.json";
  const urlFTM =
    "https://alpaca-static-api.alpacafinance.org/ftm/v1/landing/summary.json";
  const urlMoneyMarket =
    "https://alpaca-static-api.alpacafinance.org/56/v1/money-market/summary.json";
  const urlAutomatedVaultV3 =
    "https://alpaca-static-api.alpacafinance.org/56/v1/automated-vault-v3/av-summary-data.json";

  useEffect(() => {
    !refreshing && safeCallback(() => setLoading(true));

    const list: Array<string> = [
      urlBSC,
      urlFTM,
      urlMoneyMarket,
      urlAutomatedVaultV3,
    ];

    (async () => {
      const [bscData, ftmData, moneyMarketData, automatedVaultV3Data] =
        await Promise.all(list.map((url) => getData(url)));

      const bscSummary = bscData.data as ISummary;
      if (!bscSummary) {
        throw new Error("failed to parse bsc data to ISummary");
      }

      const ftmSummary = ftmData.data as ISummary;
      if (!ftmSummary) {
        throw new Error("failed to parse ftm data to ISummary");
      }

      const moneyMarketSummary = moneyMarketData as IMoneyMarketSummary;
      if (!moneyMarketSummary) {
        throw new Error(
          "failed to parse money market data to IMoneyMarketSummary"
        );
      }

      const automatedVaultV3Summary =
        automatedVaultV3Data.data as IAutomatedVaultV3Summary;
      if (!automatedVaultV3Summary) {
        throw new Error(
          "failed to parse automated vault v3 data to IAutomatedVaultV3Summary"
        );
      }

      const summary = bscSummary;
      summary.overall.bscTvl = (
        Number(bscSummary.overall.tvl) -
        Number(moneyMarketSummary.totalTvl) -
        Number(automatedVaultV3Summary.totalTvl)
      ).toString();
      summary.overall.ftmTvl = ftmSummary.overall.tvl;
      summary.overall.tvl = String(
        Number(bscSummary.overall.tvl) + Number(ftmSummary.overall.tvl)
      );

      // GrassHouse
      summary.overall.grasshouseTotalApr =
        bscSummary.overall.grasshouseTotalApr;
      summary.overall.grasshouseTotalApy =
        bscSummary.overall.grasshouseTotalApy;

      safeCallback(() => {
        setSummary(summary);
        setMoneyMarketSummary(moneyMarketSummary);
        setAutomatedVaultV3Summary(automatedVaultV3Summary);
        setLoading(false);
        setReady(true);
        setRefreshing(false);
      });
    })();
  }, [refreshTimeRef]);

  return {
    summary,
    moneyMarketSummary,
    automatedVaultV3Summary,
    loading,
    ready,
  };
}
