import { useEffect, useState } from "react";
import { useSafeCallback } from "./useSafeCallback";

const SECONDS = 1000;

type RefreshHookOutput = {
  refreshTimeRef: number;
  refreshing: boolean;
  setRefreshing: (bool: boolean) => void;
};

export function useRefreshEveryNSeconds(n: number): RefreshHookOutput {
  const [timeRef, setTimeRef] = useState<number>(0);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const safeCallback = useSafeCallback();

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().valueOf();

      safeCallback(() => {
        // important! do not change setRefreshing sequence
        setRefreshing(true);
        setTimeRef(now - (now % (n * SECONDS)));
      });
    }, n * SECONDS);
    return () => clearInterval(interval);
  }, []);

  return {
    refreshTimeRef: timeRef,
    refreshing,
    setRefreshing,
  };
}

export function useRefresh(): RefreshHookOutput {
  return useRefreshEveryNSeconds(10);
}

export function useRefresh30Seconds(): RefreshHookOutput {
  return useRefreshEveryNSeconds(30);
}

export function useRefreshOneMinute(): RefreshHookOutput {
  return useRefreshEveryNSeconds(60);
}
