import React from "react"
import cx from "classnames"
import { Trans, useTranslation } from "react-i18next"

import eclipse from "~/images/graphics/eclipse.png"
import certikLogo from "~/images/audits/certik.png"
import inspexLogo from "~/images/audits/inspex.png"
import peckshieldLogo from "~/images/audits/peckshield.png"
import slowmistLogo from "~/images/audits/slowmist.png"
import immunefiLogo from "~/images/audits/immunefi.svg"
import immunefiBG from "~/images/audits/immunefi-bg.svg"
import blocksecLogo from "~/images/audits/blocksec.png"

import AuditCard from "~/landing/AuditCard"
import SecurityHighlights from "~/landing/SecurityHightlights"

const audits = [
  {
    alt: "peckshield",
    logo: peckshieldLogo,
    count: 7,
    href: "https://docs.alpacafinance.org/transparency",
    className: "lg:order-1",
  },
  {
    alt: "certik",
    logo: certikLogo,
    count: 1,
    href: "https://docs.alpacafinance.org/transparency",
    className: "lg:order-2",
  },
  {
    alt: "inspex",
    logo: inspexLogo,
    count: 10,
    href: "https://docs.alpacafinance.org/transparency",
    className: "lg:order-4",
  },
  {
    alt: "slowmist",
    logo: slowmistLogo,
    count: 4,
    href: "https://docs.alpacafinance.org/transparency",
    className: "lg:order-3",
  },
  {
    alt: "blocksec",
    logo: blocksecLogo,
    count: 1,
    href: "https://docs.alpacafinance.org/transparency",
    className: "lg:order-5",
  },
]

const ProtocolSecurity: React.FC = () => {
  const { t } = useTranslation()

  const auditCount = audits.reduce((acc, audit) => acc + audit.count, 0)

  return (
    <section id="security" className="relative z-10 py-10 lg:py-11 mt-8">
      <img
        className="hidden lg:block lg:absolute z-0 left-44 -top-60 w-2/4 h-full filter blur-3xl transform -translate-x-1/3 opacity-60"
        src={eclipse}
      />
      <div className="relative lg:container mx-auto">
        <div className="relative px-4 lg:px-0 inline-block text-2xl font-medium lg:text-section-header-desktop mb-8 lg:mb-16">
          <div className="absolute header-background z-0 top-0 left-0 h-full w-full"></div>
          <span className="relative">{t("Protocol Security")}</span>
        </div>
        <div className="relative lg:flex mx-auto lg:gap-x-5">
          <div className="px-4 lg:px-0 lg:w-4/6">
            <div className="text-base items-center font-medium lg:flex lg:text-3xl">
              <div className="flex items-center">
                <div className="text-4xl mr-3 text-green-gradient font-bold lg:text-hero-desktop lg:font-semibold">
                  {auditCount}
                </div>
                <div className="lg:text-2xl pr-4 lg:border-r-4 border-image-green-gradient">
                  Security
                  <br className="hidden lg:block" />
                  Audits
                </div>
              </div>
              <div className="text-xs lg:pl-4 lg:text-2xl hidden lg:block">
                <Trans i18nKey="The most secure leveraged yield farming protocol (desktop)"></Trans>
              </div>
              <div className="mt-2 mb-4 text-xs lg:pl-4 lg:text-2xl block lg:hidden">
                <Trans i18nKey="The most secure leveraged yield farming protocol (mobile)"></Trans>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2 mt-2 lg:grid-cols-3 lg:gap-5">
              {audits.map(({ alt, logo, count, href, className }) => (
                <AuditCard
                  key={alt}
                  alt={alt}
                  logo={logo}
                  count={count}
                  href={href}
                  className={cx("col-span-1", className)}
                />
              ))}
              <div className="rounded-lg dark-500 border border-gray flex items-center relative overflow-hidden col-span-1 lg:order-last">
                <img
                  className="absolute h-full lg:w-full"
                  src={immunefiBG}
                  alt="Immunefi"
                />
                <div className="absolute">
                  <div className="flex justify-center items-center mt-2">
                    <img
                      className="w-4/5 lg:w-3/5"
                      src={immunefiLogo}
                      alt="Immunefi"
                    />
                  </div>
                  <div className="text-xs mt-6 font-thin lg:font-medium text-center">
                    <Trans i18nKey="joinBounty">
                      Join our Bug Bounty Program & get rewarded up to
                      <strong className="block text-green-gradient text-xl font-semibold my-2 lg:text-2xl">
                        {{ amount: "$100,000" }}
                      </strong>
                    </Trans>
                  </div>
                  <div className="text-xs font-thin text-center">
                    <Trans i18nKey="clickBounty">
                      Click{" "}
                      <a
                        href="https://immunefi.com/bounty/alpacafinance/"
                        className="font-bold"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                      >
                        here
                      </a>{" "}
                      to learn more
                    </Trans>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-2/6 rounded-2xl lg:border lg:border-transparent lg:bg-dark-500-border-green-gradient">
            <div className="mt-8 lg:mt-0 lg:w-full">
              <div className="container mx-auto px-4 lg:px-0 text-base font-medium px-0 mt-1 lg:py-4 lg:text-2xl lg:text-center lg:border-b border-image-green-gradient">
                {t("Security Highlights")}
              </div>
              <SecurityHighlights />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProtocolSecurity
