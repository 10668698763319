import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "gatsby-plugin-react-i18next";
import bloombergTv from "~/images/medias/bloomberg-tv.svg";
import foxBusiness from "~/images/medias/fox-business.svg";
import messari from "~/images/medias/messari.svg";
import cointelegraph from "~/images/medias/cointelegraph.svg";
import theDefiant from "~/images/medias/the-defiant.svg";
import coinmarketcap from "~/images/medias/coinmarketcap.svg";

const items = [
  {
    logo: bloombergTv,
    alt: "Bloomberg Television",
    link: "https://www.youtube.com/watch?v=WGb-JdI4Pc8",
  },
  {
    logo: foxBusiness,
    alt: "Fox Business",
    link: "https://newtothestreet.com/new-to-the-street-t-v-announces-the-corporate-interviews-on-its-279th-t-v-show-airing-on-fox-business-network-tonight-monday-december-20-2021-at-1030-pm-pt-and-the-280th-t-v-show-airing-tomo",
  },
  {
    logo: messari,
    alt: "Messari",
    link: "https://messari.io/article/alpaca-finance-a-leveraged-defi-product-suite",
  },
  {
    logo: cointelegraph,
    alt: "Cointelegraph",
    link: "https://cointelegraph.com/press-releases/alpaca-finance-raised-36m-in-public-sale-of-first-half-of-alpies-nft-collection",
  },
  {
    logo: theDefiant,
    alt: "The Defiant",
    link: "https://thedefiant.io/leveraged-yield-farming/",
  },
  {
    logo: coinmarketcap,
    alt: "CoinMarketCap",
    link: "https://coinmarketcap.com/earn/videos/how-to-lend-borrow-crypto-mint-stablecoins-and-earn-passive-income",
  },
];

const Medias: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className="my-12 md:my-28 lg:relative">
      <div className="container mx-auto">
        <div className="relative px-4 lg:px-0 inline-block text-2xl font-medium lg:text-section-header-desktop mb-8 lg:mb-16">
          <div className="absolute header-background z-0 top-0 left-0 h-full w-full"></div>
          <span className="relative">{t("As Featured in")}</span>
        </div>
        <div className="grid grid-cols-2 gap-x-11 gap-y-11 mx-6 md:grid-cols-3 md:mx-28 md:gap-x-32 md:gap-y-24">
          {items.map(({ logo, alt, link }) => (
            <div className="" key={alt}>
              <Link to={link} target="_blank">
                <img
                  className="lg:w-full h-auto md:mx-auto"
                  src={logo}
                  alt={alt}
                />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Medias;
