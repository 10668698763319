import React from "react";
import Slider from "react-slick";
import cx from "classnames";

import { Trans, useTranslation } from "react-i18next";
import { useWindowSize } from "~/ui/hooks";
import defiSafetyLogo from "~/images/audits/defi-safety-logo.png";
import defiSafetyScore from "~/images/audits/defi-safety-score.svg";
import bscCertikLogo from "~/images/audits/bsc-certik-logo.png";
import certikScore from "~/images/audits/certik-score.svg";
import alpacaLogo from "~/images/logos/alpaca-finance.png";
import chainlinkLogo from "~/images/logos/chainlink.png";
import nexusMutualLogo from "~/images/logos/nexus-mutual.png";
import SecurityHighlightCard from "~/landing/SecurityHighlightCard";
import chevronDownIcon from "~/images/icons/chevron-down.svg";

const Arrow = ({ direction, onClick, style }) => (
  <button onClick={onClick} style={style} type="button">
    <img
      src={chevronDownIcon}
      alt="chevron"
      className={cx(
        "absolute z-10 top-1/2 transform -translate-y-1/2 w-5",
        direction === "left" ? "left-2 rotate-90" : "right-2 -rotate-90"
      )}
    />
  </button>
);

const SecurityHighlights: React.FC = () => {
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const securityHighlights = [
    {
      key: "binance-certik",
      top: (
        <div className="relative">
          <img className="w-40 lg:w-60" src={certikScore} alt="Certik Score" />
          <img
            className="absolute left-1/2 transform -translate-x-1/2 top-full -translate-y-8 lg:-translate-y-12 w-20 lg:w-28 h-auto"
            src={bscCertikLogo}
            alt="Binance Certik Logo"
          />
        </div>
      ),
      href: "https://www.certik.com/projects/alpacafinance",
      content: t(
        "Alpaca Finance scored 3rd highest for security on BNB Chain as assessed by Certik (June, 2021)"
      ),
    },
    {
      key: "alpaca-chainlink",
      top: (
        <div className="px-4">
          <img
            className="w-3/5 mx-auto"
            src={alpacaLogo}
            alt="Alpaca Finance"
          />
          <div className="my-5 pt-px bg-white bg-opacity-40" />
          <img className="w-3/5 mx-auto" src={chainlinkLogo} alt="Chainlink" />
        </div>
      ),
      title: t("Chainlink & Alpaca Guard"),
      href: "https://medium.com/alpaca-finance/alpaca-finance-integrates-chainlink-price-feeds-to-maximize-security-of-leveraged-yield-farming-on-8f60f7589fa0",
      content: t(
        "No flash loans. Protected from flash liquidations and price manipulation."
      ),
    },
    {
      key: "nexus",
      top: <img src={nexusMutualLogo} alt="Nexus Mutual" />,
      title: t("Insurance Available"),
      href: "https://app.nexusmutual.io/cover/buy/get-quote?address=0xA625AB01B08ce023B2a342Dbb12a16f2C8489A8F",
      content: t("Integrated with Nexus Mutual Insurance"),
    },
    {
      key: "defi-safety",
      top: (
        <div className="relative">
          <img
            className="w-40 lg:w-60"
            src={defiSafetyScore}
            alt="DeFi Safety Score"
          />
          <img
            className="absolute left-1/2 transform -translate-x-1/2 top-full -translate-y-8 lg:-translate-y-14 w-12 lg:w-20 h-auto"
            src={defiSafetyLogo}
            alt="DeFi Safety Logo"
          />
        </div>
      ),
      href: "https://twitter.com/BNBCHAIN/status/1405108016945319936",
      content: (
        <Trans i18nKey="alpacaSafetyScore">
          Alpaca Finance received <br />
          <strong>the highest safety score</strong> on BSC <br />
          from DeFi Safety (June, 2021)
        </Trans>
      ),
    },
  ];
  return (
    <Slider
      className="mt-8 lg:mt-1"
      variableWidth={width < 1024}
      adaptiveHeight
      arrows={width >= 1024}
      // @ts-ignore
      nextArrow={<Arrow direction="right" />}
      // @ts-ignore
      prevArrow={<Arrow direction="left" />}
    >
      {securityHighlights.map(({ key, content, ...props }) => (
        <SecurityHighlightCard
          key={key}
          className="w-72 mx-4 lg:mx-0 lg:w-full lg:h-full"
          {...props}
        >
          {content}
        </SecurityHighlightCard>
      ))}
    </Slider>
  );
};

export default SecurityHighlights;
